// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {D_bZvl6Yg: {hover: true}, DJ0bRRDcJ: {hover: true}};

const cycleOrder = ["D_bZvl6Yg", "DJ0bRRDcJ"];

const serializationHash = "framer-6s8KY"

const variantClassNames = {D_bZvl6Yg: "framer-v-2uspmc", DJ0bRRDcJ: "framer-v-1k6in3g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {onDark: "DJ0bRRDcJ", onLight: "D_bZvl6Yg"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "D_bZvl6Yg"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "D_bZvl6Yg", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6s8KY", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"https://www.instagram.com/whiteuistore/"} openInNewTab><Image {...restProps} as={"a"} background={{alt: "Instagram", fit: "fit", intrinsicHeight: 64, intrinsicWidth: 64, pixelHeight: 64, pixelWidth: 64, src: "https://framerusercontent.com/images/H7vfw37wuCe2vnZ4kE4uscYM38.svg"}} className={`${cx("framer-2uspmc", className)} framer-1onhoug`} data-framer-name={"onLight"} layoutDependency={layoutDependency} layoutId={"D_bZvl6Yg"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"D_bZvl6Yg-hover": {opacity: 0.8}, "DJ0bRRDcJ-hover": {opacity: 0.8}}} {...addPropertyOverrides({"D_bZvl6Yg-hover": {"data-framer-name": undefined}, "DJ0bRRDcJ-hover": {"data-framer-name": undefined}, DJ0bRRDcJ: {"data-framer-name": "onDark", background: {alt: "Instagram", fit: "fit", intrinsicHeight: 96, intrinsicWidth: 96, pixelHeight: 96, pixelWidth: 96, src: "https://framerusercontent.com/images/CCPCzz9Q2U6lG7WMC5SdlqgQWME.png"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6s8KY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6s8KY .framer-1onhoug { display: block; }", ".framer-6s8KY .framer-2uspmc { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6s8KY .framer-2uspmc { gap: 0px; } .framer-6s8KY .framer-2uspmc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6s8KY .framer-2uspmc > :first-child { margin-left: 0px; } .framer-6s8KY .framer-2uspmc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DJ0bRRDcJ":{"layout":["fixed","fixed"]},"tqIK0Vztp":{"layout":["fixed","fixed"]},"VIKgY2xQO":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerLjR3tQ5wj: React.ComponentType<Props> = withCSS(Component, css, "framer-6s8KY") as typeof Component;
export default FramerLjR3tQ5wj;

FramerLjR3tQ5wj.displayName = "Social Icons / Instagram";

FramerLjR3tQ5wj.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerLjR3tQ5wj, {variant: {options: ["D_bZvl6Yg", "DJ0bRRDcJ"], optionTitles: ["onLight", "onDark"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLjR3tQ5wj, [])